@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Add this rule to prevent the flashing text cursor */
* {
  user-select: none;
}

/* Allow text selection for specific elements where it's needed */
input, textarea, [contenteditable], .allow-select {
  user-select: text;
}

.quote-bar-wrapper {
  position: relative;
}

.sticky-sentinel {
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  visibility: hidden;
}

#quote-bar {
  position: relative;
  z-index: 40;
  transition: all 0.3s ease;
}

#quote-bar.sticky-quote-bar {
  position: fixed;
  top: 80px; /* Height of the header */
  left: 0;
  right: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

body.quote-bar-sticky {
  padding-top: 144px; /* 80px for header + 64px for quote bar */
}

section:not(#hero) {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (max-width: 640px) {
  section:not(#hero) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

/* Mobile Menu Styles */
.mobile-menu {
  display: none;
  position: fixed;
  top: 80px; /* Height of the header */
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 45;
  overflow-y: auto;
  transition: all 0.3s ease;
}

.mobile-menu.open {
  display: block;
}

.mobile-menu a {
  display: block;
  padding: 15px 20px;
  font-size: 18px;
  color: #333;
  border-bottom: 1px solid #eee;
  transition: all 0.2s ease;
}

.mobile-menu a:hover {
  background-color: #f8f8f8;
  color: #FF004E;
}

/* Hamburger Menu Icon */
.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 30px;
  height: 3px;
  background-color: #333;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

/* Hamburger Animation */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* Custom styles for the geocoder input */
.custom-geocoder-container {
  width: 100%;
}

.custom-geocoder-container .mapboxgl-ctrl-geocoder {
  width: 100%;
  max-width: none;
  box-shadow: none;
  font-size: 14px;
  min-width: 100%;
}

.custom-geocoder-container .mapboxgl-ctrl-geocoder--input {
  height: 48px;
  padding: 6px 35px;
  border-radius: 0.375rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
  color: #4a5568;
  font-size: 16px;
  transition: all 0.15s ease;
  -webkit-appearance: none;
}

@media (min-width: 640px) {
  .custom-geocoder-container .mapboxgl-ctrl-geocoder--input {
    height: 40px;
    font-size: 14px;
  }
}

.custom-geocoder-container .mapboxgl-ctrl-geocoder--input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.95);
}

.custom-geocoder-container .mapboxgl-ctrl-geocoder--icon {
  fill: #6B7280;
}

.custom-geocoder-container .mapboxgl-ctrl-geocoder--icon-search {
  top: 14px;
  left: 10px;
  width: 20px;
  height: 20px;
}

@media (min-width: 640px) {
  .custom-geocoder-container .mapboxgl-ctrl-geocoder--icon-search {
    top: 10px;
  }
}

.custom-geocoder-container .mapboxgl-ctrl-geocoder--icon-close {
  right: 10px;
  top: 14px;
  width: 20px;
  height: 20px;
}

@media (min-width: 640px) {
  .custom-geocoder-container .mapboxgl-ctrl-geocoder--icon-close {
    top: 10px;
  }
}

.custom-geocoder-container .mapboxgl-ctrl-geocoder--pin-right {
  top: 7px;
  right: 7px;
}

.custom-geocoder-container .suggestions {
  background-color: white;
  border-radius: 0.375rem;
  margin-top: 4px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.custom-geocoder-container .suggestions > .active > a,
.custom-geocoder-container .suggestions > li > a:hover {
  background-color: #F3F4F6;
}

.custom-geocoder-container .mapboxgl-ctrl-geocoder--suggestion {
  padding: 8px 12px;
}

.custom-geocoder-container .mapboxgl-ctrl-geocoder--suggestion-title {
  font-size: 14px;
  font-weight: 500;
  color: #1F2937;
}

.custom-geocoder-container .mapboxgl-ctrl-geocoder--suggestion-address {
  font-size: 12px;
  color: #6B7280;
}

/* Add a custom class for the location pin icon */
.location-pin-icon::before {
  content: '\f3c5'; /* Unicode for the location pin icon in Font Awesome */
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #4a5568;
}
